.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.social-container {
  padding: 25px 50px;
}
a.social {
  margin: 1rem 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-5px);
}
a.linkedin {
  color: #0077b5;
}
a.twitter {
  color: #55acee;
}
a.facebook {
  color: #3b5999;
}
a.instagram {
  color: #e4405f;
}
a.github {
  color: black;
}